export default function GoogleTag({ tagID }: { tagID: string | null }) {
  if (!tagID) return null
  return (
    <>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${tagID}`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${tagID}');
        `,
        }}
      />
    </>
  )
}
