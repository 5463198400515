import { Avatar, AvatarFallback } from '#app/components/ui/avatar.js'
import { ROUTE_PATH as LOGIN_PATH } from '#app/routes/onboarding+/username'
import { ROUTE_PATH as DASHBOARD_PATH } from '#app/routes/auth+/login'
import { prisma } from '#app/utils/db.server.js'
import type { LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { json } from '@remix-run/node'
import { GenericErrorBoundary } from '#app/components/misc/error-boundary.js'
import { buttonVariants } from '#app/components/ui/button.js'
import { BadgePlus, ExternalLink } from 'lucide-react'
import { AvatarImage } from '@radix-ui/react-avatar'
import { LeadForm } from '#app/components/lead-form'
import { Footer } from '#app/components/footer'
import { authenticator } from '#app/modules/auth/auth.server.js'
import { useTranslation } from 'react-i18next'
import UserLocation from '#app/components/user-location.js'
import SocialIcons from '#app/components/social-icons.js'
import { generateColorScheme } from '#app/utils/colorUtils.js'
import PropertyList from '#app/components/property-list.js'
import ReviewList from '#app/components/review-list.js'
import GoogleTag from '#app/components/google-tag.js'
import MetaPixel from '#app/components/meta-pixel.js'

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  if (!data?.profilePage) {
    return [
      { title: 'Profile Not Found' },
      { name: 'description', content: 'Sorry, this profile could not be found.' },
    ]
  }

  const { profilePage } = data
  return [
    { title: `${profilePage.fullName} - ${profilePage.subheading ?? 'immotree'}` },
    { name: 'description', content: profilePage.bio?.substring(0, 160) },
    { name: 'og:title', content: `${profilePage.fullName} - ${profilePage.subheading}` },
    { name: 'og:description', content: profilePage.bio?.substring(0, 160) },
    {
      name: 'og:image',
      content: profilePage.coverUrl ?? `https://immotr.ee/placeholder.svg`,
    },
    { name: 'og:type', content: 'profile' },
    { name: 'twitter:card', content: 'summary_large_image' },
    {
      name: 'twitter:title',
      content: `${profilePage.fullName} - ${profilePage.subheading}`,
    },
    { name: 'twitter:description', content: profilePage.bio?.substring(0, 160) },
    {
      name: 'twitter:image',
      content: profilePage.coverUrl ?? `https://immotr.ee/placeholder.svg`,
    },
  ]
}

export function ErrorBoundary() {
  const { t } = useTranslation()
  return (
    <GenericErrorBoundary
      statusHandlers={{
        404: () => (
          <div className="flex h-screen w-full flex-col items-center justify-center gap-8 rounded-md bg-card px-6">
            <img
              src="https://res.cloudinary.com/dwo1ijvxo/image/upload/f_auto,q_auto/v1/immotree/landing_page/u2svr8j78cy6jeln6cwk"
              alt="404"
            />
            <div className="flex flex-col items-center gap-2">
              <p className="text-2xl font-medium text-primary">Whoops!</p>
              <p className="text-center text-lg font-normal text-primary/60">
                {t('public_profile_not_found')}
              </p>
            </div>
            <div className="flex gap-4">
              <Link
                to="/"
                prefetch="intent"
                className={`${buttonVariants({ variant: 'secondary', size: 'sm' })} gap-2`}>
                <span className="text-primary-forground text-sm font-medium group-hover:text-primary">
                  Home
                </span>
                <ExternalLink className="text-primary-forground h-4 w-4 stroke-[1.5px] group-hover:text-primary" />
              </Link>
              <Link
                to="/onboarding/username"
                prefetch="intent"
                className={`${buttonVariants({ variant: 'default', size: 'sm' })} gap-2`}>
                <span className="text-primary-forground text-sm font-medium group-hover:text-primary">
                  {t('public_profile_not_found_cta')}
                </span>
                <ExternalLink className="text-primary-forground h-4 w-4 stroke-[1.5px] group-hover:text-primary" />
              </Link>
            </div>
          </div>
        ),
      }}
    />
  )
}
export async function loader({ request, params }: LoaderFunctionArgs) {
  const sessionUser = await authenticator.isAuthenticated(request)
  const { username } = params

  if (!username) {
    throw new Response('Not found', { status: 404 })
  }

  const user = await prisma.user.findUnique({
    where: { username },
    include: {
      image: { select: { id: true } },
      properties: { include: { images: true } },
      AgentReview: true,
    },
  })

  if (!user) {
    throw new Response('Not found', { status: 404 })
  }
  const profilePage = await prisma.profile.findUnique({
    where: { userId: user.id },
    include: {
      user: true,
      includedReviews: true,
      socialProofLogos: true,
      includedProperties: {
        include: { images: true },
      },
    },
  })
  if (!profilePage) {
    throw new Response('Not found', { status: 404 })
  }
  const url = new URL(request.url)
  url.searchParams.forEach((value, key) => {
    url.searchParams.delete(key)
  })
  const canonicalUrl = url.toString()
  const colorScheme = generateColorScheme(profilePage.backgroundColor)
  return json({ sessionUser, profilePage, colorScheme, canonicalUrl })
}

export default function Username() {
  const { profilePage, sessionUser, colorScheme } = useLoaderData<typeof loader>()
  return (
    <div
      style={{ backgroundColor: profilePage.backgroundColor }}
      className="mx-auto flex min-h-[100dvh] max-w-2xl flex-col rounded-t-xl pt-10">
      <div className="flex flex-col items-center justify-center px-2 sm:px-4">
        <Avatar className="h-32 w-32 border-4 border-border shadow-md">
          <AvatarImage
            className="h-full w-full rounded-[inherit] bg-white object-cover"
            src={profilePage.avatarUrl ?? '/placeholder.svg'}
            alt={profilePage.fullName}
          />
          <AvatarFallback>
            {profilePage?.fullName
              ?.split(' ')
              .map((n: string) => n[0])
              .join('')}
          </AvatarFallback>
        </Avatar>
      </div>
      <div
        style={{ color: profilePage.textColor }}
        className="mt-4 w-full px-2 text-center text-primary sm:px-4">
        <h1 className="text-4xl font-bold">{profilePage.fullName}</h1>
        <p className="text-base">{profilePage.subheading}</p>
      </div>
      <div className="mt-3 w-full" />
      <UserLocation
        textColor={profilePage.textColor}
        colorScheme={colorScheme}
        city={profilePage.user.city}
      />
      <div className="mx-auto px-4 sm:px-2">
        <SocialIcons
          textColor={profilePage.textColor}
          colorScheme={[profilePage.backgroundColor]}
          phone={profilePage.phone}
          email={profilePage.email}
          linkedin={profilePage.linkedin}
          instagram={profilePage.instagram}
          youtube={profilePage.youtube}
          facebook={profilePage.facebook}
          twitter={profilePage.twitter}
        />
      </div>
      <div className="mt-3 w-full" />
      <div className="container mx-auto px-4 sm:px-2">
        {profilePage.bio ? (
          <div className="w-full text-center">
            <p style={{ color: profilePage.textColor }} className="text-base">
              {profilePage.bio}
            </p>
          </div>
        ) : null}
        <div className="mt-12 w-full" />
        <LeadForm
          backgroundColor={profilePage.backgroundColor}
          textColor={profilePage.textColor}
          userId={profilePage.userId}
        />

        <div className="mt-12 w-full" />
        <PropertyList properties={profilePage.includedProperties} />
        <div className="mt-12 w-full" />
        <ReviewList reviews={profilePage.includedReviews} />
        <div className="mt-12 w-full" />
        <div className="mx-auto flex flex-wrap items-center justify-center gap-2">
          {profilePage.socialProofLogos?.map((logo) => (
            <img
              className="h-24 w-24 rounded object-cover"
              key={logo.id}
              src={logo.url}
              alt="Social Proof"
            />
          ))}
        </div>
        <div className="mt-12 w-full" />
        <div className="flex flex-col items-center">
          {sessionUser ? (
            <Link
              to={sessionUser ? DASHBOARD_PATH : LOGIN_PATH}
              style={{ color: colorScheme[9], backgroundColor: colorScheme[0] }}
              className={`${buttonVariants({ size: 'sm' })} w-full max-w-xs rounded-xl py-7`}>
              <BadgePlus className="mr-2 h-4 w-4" />
              {sessionUser ? 'Dashboard' : 'Create your immotr.ee'}
            </Link>
          ) : null}
        </div>
        <MetaPixel pixelID={profilePage.metaPixel} />
        <GoogleTag tagID={profilePage.googleTagManager} />
        <Footer textColor={colorScheme[10]} colorScheme={colorScheme} />
      </div>
    </div>
  )
}
